(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("brace"));
	else if(typeof define === 'function' && define.amd)
		define("bin-ace-editor", ["brace"], factory);
	else if(typeof exports === 'object')
		exports["bin-ace-editor"] = factory(require("brace"));
	else
		root["bin-ace-editor"] = factory(root["ace"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__194__) {
return 